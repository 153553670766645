import React from "react"
import format from "date-fns/format"
import { Link } from "gatsby"

export const PostItem = ({ slug, date, title, description }) => (
  <article>
    <Link to={`/${slug}`}>
      <div className="group mb-14">
        <p className="block text-gray-500 uppercase font-semibold text-xs tracking-wide mb-1">
          {format(new Date(date), "MMMM do, yyyy")}
        </p>
        <p className="block mt-1 mb-2 text-lg leading-tight font-medium text-black flex-grow-0">
          {title}
        </p>
        <p className="text-gray-600 text-lg mb-4">{description}</p>

        <p className="text-gray-700 hover:text-black text-sm no-underline group-hover:underline text-base leading-normal">
          Read this article →
        </p>
      </div>
    </Link>
  </article>
)
