import React from "react"

export const Hero = ({ title, subtitle, children, pretext }) => (
  <div className="bg-gradient-to-b from-brand-blue to-brand-blue-darker border-b">
    <div className="pt-8 pb-20 border-b border-gray-300">
      <div className="container mx-auto px-8 max-w-screen-md">
        <p className="pt-8 uppercase text-base font-semibold text-gray-200">
          {pretext}
        </p>
        {title && (
          <h1 className="font-bold text-5xl leading-none text-white pt-1">
            {title}
          </h1>
        )}
        {subtitle && (
          <h2 className="pt-6 text-xl font-normal text-white">{subtitle}</h2>
        )}

        {children}
      </div>
    </div>
  </div>
)
