import * as React from "react"
import { graphql } from "gatsby"
import { orderBy } from "lodash"

import { SEO } from "../components/SEO"
import { DefaultLayout } from "../layouts/Default"
import { Hero } from "../components/Hero"
import { PostItem } from "../components/PostList"

const title = "React Native Tutorials"
const description =
  "A comprehensive collection of React Native tips, tricks, and tutorials."

const IndexPage = ({ data }) => {
  // TODO: Use gatsby sort instead of lodash. It's broken with gatsby right now.
  const sortedPosts = orderBy(
    data.blog.edges,
    ["node.frontmatter.date"],
    ["desc"]
  )

  return (
    <DefaultLayout>
      <SEO title={title} description={description} />
      <Hero title={title} subtitle={description} />

      <div className="py-10">
        <section className="container mx-auto px-8 max-w-screen-md">
          {sortedPosts.map(({ node: { frontmatter } }) => (
            <PostItem key={frontmatter.slug} {...frontmatter} />
          ))}
        </section>
      </div>
    </DefaultLayout>
  )
}

export default IndexPage

export const query = graphql`
  {
    blog: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { glob: "**/content/blog/**" }
        frontmatter: { published: { eq: true } }
      } # sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          frontmatter {
            title
            description
            date
            slug
          }
        }
      }
    }
  }
`
